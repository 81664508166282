import i18n from '@/languages/i18n';

export enum PlcDataType {
  Boolean = 0,
  Byte = 1,
  Short = 2,
  Integer = 3,
  Long = 4,
  UnsignedByte = 5,
  UnsignedShort = 6,
  UnsignedInteger = 7,
  UnsignedLong = 8,
  Float = 9,
  Double = 10,
  String = 11,
  Date = 12,
  DateTime = 13,
  Time = 14
}

export const PlcDataTypeDropdown = () => [
  {
    id: PlcDataType.Boolean,
    title: i18n.t('dropdowns.plcDataTypes.boolean').toString()
  },
  {
    id: PlcDataType.Byte,
    title: i18n.t('dropdowns.plcDataTypes.byte').toString()
  },
  {
    id: PlcDataType.Short,
    title: i18n.t('dropdowns.plcDataTypes.short').toString()
  },
  {
    id: PlcDataType.Integer,
    title: i18n.t('dropdowns.plcDataTypes.integer').toString()
  },
  {
    id: PlcDataType.Long,
    title: i18n.t('dropdowns.plcDataTypes.long').toString()
  },
  {
    id: PlcDataType.UnsignedByte,
    title: i18n.t('dropdowns.plcDataTypes.unsignedByte').toString()
  },
  {
    id: PlcDataType.UnsignedShort,
    title: i18n.t('dropdowns.plcDataTypes.unsignedShort').toString()
  },
  {
    id: PlcDataType.UnsignedInteger,
    title: i18n.t('dropdowns.plcDataTypes.unsignedInteger').toString()
  },
  {
    id: PlcDataType.UnsignedLong,
    title: i18n.t('dropdowns.plcDataTypes.unsignedLong').toString()
  },
  {
    id: PlcDataType.Float,
    title: i18n.t('dropdowns.plcDataTypes.float').toString()
  },
  {
    id: PlcDataType.Double,
    title: i18n.t('dropdowns.plcDataTypes.double').toString()
  },
  {
    id: PlcDataType.String,
    title: i18n.t('dropdowns.plcDataTypes.string').toString()
  },
  {
    id: PlcDataType.Date,
    title: i18n.t('dropdowns.plcDataTypes.date').toString()
  },
  {
    id: PlcDataType.DateTime,
    title: i18n.t('dropdowns.plcDataTypes.dateTime').toString()
  },
  {
    id: PlcDataType.Time,
    title: i18n.t('dropdowns.plcDataTypes.time').toString()
  }
];

export default PlcDataType;
