var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12"},[_c('content-card',{attrs:{"title":_vm.$t('views.systemadmin.fieldDefinitions.title'),"cardIcon":"fas fa-draw-polygon"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-12 scrollable"},[_c('smart-table',{ref:"smartTable",attrs:{"language":"de","config":_vm.smartTableConfig,"selected":_vm.selected,"stickyHeader":true,"reloadDataOnUpdate":false,"showLoadingSpinnerBelowHeaders":true,"responsive":"","smaller":"","striped":""},on:{"update:selected":function($event){_vm.selected=$event},"rowupdated":_vm.reloadTableIfNecessary,"rowadded":_vm.reloadSite},scopedSlots:_vm._u([{key:"cell-edit",fn:function(ref){
var column = ref.column;
var currentValue = ref.currentValue;
var setNewValue = ref.setNewValue;
var row = ref.row;
return [(column.fieldName === 'defaultValue')?[_c('FieldDefinitionDefaultValueEditCell',{attrs:{"row":row,"colors":_vm.colors,"modalities":_vm.modalities,"currentValue":currentValue,"setNewValue":setNewValue}})]:_vm._e()]}},{key:"cell-readonly",fn:function(ref){
var column = ref.column;
var currentValue = ref.currentValue;
var row = ref.row;
return [(
                      column.fieldName === 'displayNameResourceId' ||
                      column.fieldName === 'descriptionResourceId'
                    )?[_c('div',{staticClass:"d-flex justify-content-between localized-text-cell"},[_c('span',[_vm._v(_vm._s(_vm.$t(currentValue)))]),_c('button',{staticClass:"ml-1 mr-2 btn btn-primary btn-sm float-right localization-button",on:{"click":function($event){return _vm.showTranslations(currentValue)}}},[_c('em',{staticClass:"fas fa-language"})])])]:(column.fieldName === 'defaultValue')?[_c('FieldDefinitionDefaultValueDisplayCell',{attrs:{"row":row,"current-value":currentValue,"colors":_vm.colors,"modalities":_vm.modalities}})]:(column.fieldName === 'validationResult')?_c('div',_vm._l((_vm.getValidationResults(currentValue)),function(validationResult){return _c('div',{key:validationResult.OpcUaPath},[_c('div',{class:validationResult.IsValid ? 'badge badge-success' : 'badge badge-danger',attrs:{"title":validationResult.IsValid
                            ? validationResult.OpcUaPath
                            : validationResult.ErrorMessage}},[_vm._v(" "+_vm._s(_vm.$t('views.systemadmin.fieldDefinitions.readValue') + ': ' + validationResult.ReadValue)+" ")])])}),0):_vm._e()]}}])})],1)])]),_c('sticky-actionbar',{scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',[_c('button',{staticClass:"btn btn-primary mx-1",on:{"click":_vm.$refs.smartTable.startAdding}},[_c('em',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('buttons.new'))+" ")]),_c('button',{staticClass:"btn btn-primary mx-1",attrs:{"disabled":_vm.validationInProgress},on:{"click":_vm.validateFieldDefinitions}},[_c('em',{staticClass:"fas fa-tasks"}),_vm._v(" "+_vm._s(_vm.$t('buttons.validate'))+" ")]),(_vm.validationInProgress)?_c('em',{staticClass:"fas fa-spinner"}):_vm._e()])]},proxy:true},{key:"right",fn:function(){return [_c('div',[_c('button',{staticClass:"btn btn-danger mx-1",attrs:{"disabled":!_vm.selected || _vm.selected.isSystemRelevant},on:{"click":_vm.$refs.smartTable.deleteSelected}},[_c('em',{staticClass:"fas fa-trash"}),_vm._v(" "+_vm._s(_vm.$t('buttons.delete'))+" ")])])]},proxy:true}])})]},proxy:true}])}),_c('LocalizationEditorModal',{ref:"localizationModal",attrs:{"resource-ids":_vm.selectedResourceIds,"category":"FieldDefinition"},on:{"didUpdateLocalization":function($event){return _vm.onLocalizationUpdatedHandler(_vm.$refs.smartTable)}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }