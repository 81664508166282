
































































































































import Vue from 'vue';
import axios from 'axios';
import {
  SmartTable,
  Config,
  Column,
  Sorting,
  PagingOptions,
  toDateTimeString
} from 'rey-vue-smarttable';
import FieldDefinition from '@/models/FieldDefinition';
import { PlcDataType, PlcDataTypeDropdown } from '@/models/PlcDataType';
import { UiControlType, UiControlTypeDropdown } from '@/models/UiControlType';
import { EntityType, EntityTypeDropdown } from '@/models/EntityType';
import OpcUaValidationResult from '@/models/OpcUaValidationResult';

import fieldDefinitionLoader from '@/services/FieldDefinitionLoader';
import Color from '@/models/Color';
import FieldDefinitionDefaultValueDisplayCell from '@/components/FieldDefinitionDefaultValueDisplayCell.vue';
import FieldDefinitionDefaultValueEditCell from '@/components/FieldDefinitionDefaultValueEditCell.vue';
import Modality from '@/models/Modality';
import LocalizationEditorModal from '@/components/LocalizationEditorModal.vue';
import { reloadActiveLanguage } from '@/languages/i18n';
import Guid from 'devextreme/core/guid';

export default Vue.extend({
  name: 'fieldDefinitions',
  components: {
    LocalizationEditorModal,
    FieldDefinitionDefaultValueEditCell,
    FieldDefinitionDefaultValueDisplayCell,
    SmartTable
  },
  data() {
    return {
      UiControlType,
      colors: [] as Color[],
      modalities: [] as Modality[],
      selectedResourceIds: [] as string[],
      smartTableConfig: new Config(
        (row) => row.fieldDefinitionId,
        [
          new Column({
            title: this.$t('views.systemadmin.fieldDefinitions.tableHeaders.entityType').toString(),
            fieldName: 'entityType',
            fieldType: 'dropdown',
            editable: (x) => !x.isSystemRelevant,
            cellStyling: (x) => ({
              'text-muted': x.isSystemRelevant
            }),
            dropdownOptions: EntityTypeDropdown(),
            defaultValue: EntityType.Article
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.plcDataType'
            ).toString(),
            fieldName: 'plcDataType',
            fieldType: 'dropdown',
            editable: () => true,
            dropdownOptions: PlcDataTypeDropdown(),
            defaultValue: PlcDataType.Integer
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.uiControlType'
            ).toString(),
            fieldName: 'uiControlType',
            fieldType: 'dropdown',
            editable: () => false,
            cellStyling: () => ({
              'text-muted': true
            }),
            dropdownOptions: UiControlTypeDropdown(),
            defaultValue: UiControlType.Text
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.displayName'
            ).toString(),
            fieldName: 'displayNameResourceId',
            fieldType: 'string',
            editable: () => false,
            hiddenInAddDialog: true,
            defaultValue: 'FieldDefinition_' + new Guid() + '_DisplayName'
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.description'
            ).toString(),
            fieldName: 'descriptionResourceId',
            fieldType: 'string',
            editable: () => false,
            hiddenInAddDialog: true,
            defaultValue: 'FieldDefinition_' + new Guid() + '_Description'
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.defaultValue'
            ).toString(),
            fieldName: 'defaultValue',
            fieldType: 'string',
            editable: () => true,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t('views.systemadmin.fieldDefinitions.tableHeaders.externalId').toString(),
            fieldName: 'externalId',
            fieldType: 'string',
            editable: () => true
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.stringMaxLength'
            ).toString(),
            fieldName: 'stringMaxLength',
            fieldType: 'numeric',
            editable: (x) => !x.isSystemRelevant,
            cellStyling: (x) => ({
              'text-muted': x.isSystemRelevant
            })
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.numberMinValue'
            ).toString(),
            fieldName: 'numberMinValue',
            fieldType: 'numeric',
            editable: (x) => !x.isSystemRelevant,
            cellStyling: (x) => ({
              'text-muted': x.isSystemRelevant
            })
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.numberMaxValue'
            ).toString(),
            fieldName: 'numberMaxValue',
            fieldType: 'numeric',
            editable: (x) => !x.isSystemRelevant,
            cellStyling: (x) => ({
              'text-muted': x.isSystemRelevant
            })
          }),
          new Column({
            title: this.$t('views.systemadmin.fieldDefinitions.tableHeaders.sortOrder').toString(),
            fieldName: 'sortOrder',
            fieldType: 'numeric',
            editable: () => true,
            defaultValue: 0
          }),
          new Column({
            title: this.$t('views.systemadmin.fieldDefinitions.tableHeaders.isDisabled').toString(),
            fieldName: 'isDisabled',
            fieldType: 'boolean',
            editable: () => true
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.isDisplayOnly'
            ).toString(),
            fieldName: 'isDisplayOnly',
            fieldType: 'boolean',
            editable: () => false
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.validatedAt'
            ).toString(),
            fieldName: 'validatedAt',
            fieldType: 'string',
            editable: () => false,
            cellStyling: (x: FieldDefinition) => {
              return {
                'text-muted': true,
                hiddenCellValue: x.isDisplayOnly
              };
            },
            converter: toDateTimeString,
            hiddenInAddDialog: true
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.validationResult'
            ).toString(),
            fieldName: 'validationResult',
            fieldType: 'string',
            editable: () => false,
            hiddenInAddDialog: true,
            cellStyling: (x) => {
              return { hiddenCellValue: x.isDisabled || x.isDisplayOnly };
            }
          }),
          new Column({
            title: this.$t('views.systemadmin.fieldDefinitions.tableHeaders.opcUaPath').toString(),
            fieldName: 'opcUaPath',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue) => {
              const isValid =
                newValue == null || newValue == '' || (!!newValue && newValue.length <= 255);
              const errorMessages = [];
              if (!isValid) {
                errorMessages.push(
                  this.$t('validationErrors.invalidTextLength', {
                    currentValue: newValue,
                    maxLength: 255
                  }).toString()
                );
              }

              return {
                isValid: isValid,
                errorMessages: errorMessages
              };
            }
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.secondaryOpcUaPath'
            ).toString(),
            fieldName: 'secondaryOpcUaPath',
            fieldType: 'string',
            editable: () => true,
            validator: (newValue) => {
              const isValid =
                newValue == null || newValue == '' || (!!newValue && newValue.length <= 255);
              const errorMessages = [];
              if (!isValid) {
                errorMessages.push(
                  this.$t('validationErrors.invalidTextLength', {
                    currentValue: newValue,
                    maxLength: 255
                  }).toString()
                );
              }

              return {
                isValid: isValid,
                errorMessages: errorMessages
              };
            }
          }),
          new Column({
            title: this.$t(
              'views.systemadmin.fieldDefinitions.tableHeaders.isConsistencyCheckEnabled'
            ).toString(),
            fieldName: 'isConsistencyCheckEnabled',
            fieldType: 'boolean',
            editable: () => true
          }),
          new Column({
            title: this.$t('views.systemadmin.fieldDefinitions.tableHeaders.id').toString(),
            fieldName: 'fieldDefinitionId',
            fieldType: 'string',
            editable: () => false,
            cellStyling: () => ({
              'text-muted': true
            }),
            hidden: false,
            hiddenInAddDialog: true
          })
        ],
        new Sorting('sortOrder', 'ascending'),
        new PagingOptions(100)
      ).withEfApiActions(axios, 'FieldDefinitions'),
      selected: undefined as FieldDefinition | undefined,
      validationInProgress: false as boolean
    };
  },
  async mounted() {
    this.colors = await fieldDefinitionLoader.loadColors();
    this.modalities = await fieldDefinitionLoader.loadModalities();
  },
  methods: {
    async validateFieldDefinitions() {
      this.validationInProgress = true;
      await this.$httpActions
        .executePost('ValidateFieldDefinitions', '{}')
        .catch(() => (this.validationInProgress = false))
        .finally(() => {
          this.validationInProgress = false;
          // @ts-ignore
          this.$refs.smartTable.reload();
        });
    },
    getValidationResults(validationResultString: string): OpcUaValidationResult[] {
      return JSON.parse(validationResultString);
    },
    reloadTableIfNecessary(input: FieldDefinition) {
      // Attributes could change depending on opcUaPath -> need to reload to get changes.
      if (this.selected?.opcUaPath != input.opcUaPath) {
        // @ts-ignore
        this.$refs.smartTable.reload();
      }
    },
    showTranslations(resourceId: string) {
      this.selectedResourceIds = [resourceId];
      // @ts-ignore
      this.$refs.localizationModal.show();
    }, // eslint-disable-next-line
    async onLocalizationUpdatedHandler(smartTable: any) {
      await reloadActiveLanguage();
      smartTable.reload(true);
    },
    reloadSite() {
      window.location.reload();
    }
  }
});
