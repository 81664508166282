
































import Vue from 'vue';
import { RetentionPeriodDropdown } from '@/models/RetentionPeriod';
import UiControlType from '@/models/UiControlType';
import Modality, { modalityDisplayTitle } from '@/models/Modality';
export default Vue.extend({
  props: ['row', 'currentValue', 'colors', 'modalities'],
  name: 'FieldDefinitionDefaultValueDisplayCell',
  data() {
    return {
      RetentionPeriodDropdown,
      UiControlType
    };
  },
  methods: {
    modalityDisplayValue(value: string | undefined): string {
      if (!value) {
        return '';
      }
      const modality = this.modalities.find((x: Modality) => x.modalityId == value);
      return modality
        ? modalityDisplayTitle(modality)
        : this.$t('views.admin.modalities.unknownModality');
    },
    getRetentionPeriodValue(currentValue: string) {
      return RetentionPeriodDropdown().find((x) => x.id.toString() === currentValue)?.title ?? '';
    },
    getIcons(currentValue: string): string[] {
      return JSON.parse(currentValue);
    }
  }
});
