



















































































import Vue from 'vue';
import { RetentionPeriodDropdown } from '@/models/RetentionPeriod';
import fieldDefinitionLoader from '@/services/FieldDefinitionLoader';
import UiControlType from '@/models/UiControlType';
import Modality, { modalityDisplayTitle } from '@/models/Modality';

export default Vue.extend({
  props: ['row', 'currentValue', 'colors', 'modalities', 'setNewValue'],
  name: 'FieldDefinitionDefaultValueEditCell',
  data() {
    return {
      RetentionPeriodDropdown,
      UiControlType
    };
  },
  methods: {
    displayTitleForModality(modality: Modality) {
      return modalityDisplayTitle(modality);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateValue(event: any) {
      const value =
        event.target.type === 'checkbox'
          ? event.target.checked.toString()
          : event.target.value.toString();
      const validationResult = fieldDefinitionLoader.isValidValue(value, this.row);
      if (validationResult.isValid) {
        event.target.setCustomValidity('');
        event.target.reportValidity();
        this.setNewValue(value);
        return true;
      } else {
        event.target.setCustomValidity(validationResult.errorMessages.join('\n'));
        event.preventDefault();
        event.stopPropagation();
        return false;
      }
    }
    // eslint-enable-next-line @typescript-eslint/no-explicit-any
  }
});
